import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload
} from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

import React from 'react'
import UserTypes from '../../types/UserTypes'
import ModalResponse from '../Shared/ModalResponse'
import CardInfo from '../CardInfo'
import {
  StyledCardSectionWhiteForm,
  StyledFormYoloPagoContainer,
  StyledTermsAndConditions,
  StyledUploadFilesButtons,
  TitleYolopago,
  InfoTextCard,
  StyledSelect,
  StyledUploadFile,
  StyledUploadOutlined,
  StyledInput
} from './styledComponents'
import { StyledButtonPrimary } from '../Storybook/Button/styledComponents'
import {
  IneModalContent,
  AdrressModalContent,
  BankStatementContent,
  invalidSection
} from './utils'
import {
  generalKeywords,
  modeloramaKeywords,
  commerceKeywords,
  clabeKeywords
} from '../../data/constants/yoloPagoKeywords'
const { Option } = Select

type propsHelp = {
  isModalVisible: boolean
  content: any
}

type props = {
  form: FormInstance
  partnerData: UserTypes.Partner | undefined
  yoloData: any
  countyData: any
  onFinish: (data: any) => void
  onChangeStores: (storeID: any) => any
  onChangeTypePerson: (e: any) => void
  normFile: (e: any, id: number, type: string) => void
  onClickTermsAndConditions: () => void
  typePerson: number
  yoloPagoAffiliation: any
  help: propsHelp
  onHelpClose: () => void
  onHelpChange: (content: any) => void
  onFieldsChange: () => void
  disabled: boolean
  handleBeforeUplad: (file: any, type: string) => any
  files: any
  handleClabeChange: (e: any) => void
}

type propsCardError = {
  status?: string
  message?: string
}

const CardError: React.FC<propsCardError> = ({ status, message }) => {
  return (
    <CardInfo
      content={[
        <>
          <InfoTextCard>
            <b>{status}</b>
          </InfoTextCard>
          <InfoTextCard>{message}</InfoTextCard>
        </>
      ]}
    />
  )
}

const FormYoloPago: React.FC<props> = ({
  form,
  partnerData,
  yoloData,
  onChangeStores,
  onFinish,
  onClickTermsAndConditions,
  normFile,
  onChangeTypePerson,
  typePerson,
  yoloPagoAffiliation,
  help,
  onHelpClose,
  onHelpChange,
  onFieldsChange,
  disabled,
  handleBeforeUplad,
  files,
  handleClabeChange,
  countyData
}) => {
  const StatusID = yoloPagoAffiliation?.StatusID
  const MessageContent = yoloPagoAffiliation?.MessageProcess

  const initalValues = {
    name: partnerData?.getProfile?.name,
    middleName: partnerData?.getProfile?.middleName,
    familyName: partnerData?.getProfile?.familyName,
    rfc: partnerData?.getProfile?.rfc,
    curp: partnerData?.getProfile?.curp,
    email: partnerData?.getProfile?.email,
    mobilePhone: partnerData?.getProfile?.mobilePhone,
    ...form.getFieldsValue()
  }

  return (
    <StyledFormYoloPagoContainer>
      <TitleYolopago>YOLOPAGO</TitleYolopago>
      {StatusID === 3 && (
        <CardError
          status={'Se ha rechazado tu vinculación'}
          message={MessageContent?.message}
        ></CardError>
      )}
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={initalValues}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <StyledCardSectionWhiteForm
          failed={
            StatusID === 3 &&
            invalidSection(MessageContent?.message, generalKeywords)
          }
        >
          <h2>1. DATOS GENERALES</h2>
          {StatusID === 3 &&
          invalidSection(MessageContent?.message, generalKeywords) ? (
            <CardError
              status={'Se ha rechazado tu vinculación'}
              message={MessageContent?.message}
            ></CardError>
          ) : (
            <CardInfo
              content={[
                <InfoTextCard>
                  Revisa la siguiente información, en caso de que tus datos no
                  sean correctos o necesites actualizarlos por favor comunícate
                  con soporte para corregirlos en el <b>WhatsApp 5535784444</b>
                </InfoTextCard>
              ]}
            />
          )}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Nombre (s)"
                name="name"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Apellido paterno"
                name="middleName"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Apellido materno"
                name="familyName"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item label="RFC" extra="12 dígitos" name="rfc">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item label="CURP" extra="18 dígitos" name="curp">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Correo electrónico"
                extra="correo@dominio.com"
                name="email"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Teléfono celular"
                extra="10 dígitos"
                name="mobilePhone"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </StyledCardSectionWhiteForm>

        <StyledCardSectionWhiteForm
          failed={
            StatusID === 3 &&
            invalidSection(MessageContent?.message, modeloramaKeywords)
          }
        >
          <h2>2. TU MODELORAMA </h2>
          {StatusID === 3 &&
          invalidSection(MessageContent?.message, modeloramaKeywords) ? (
            <CardError
              status={'Se ha rechazado tu vinculación'}
              message={MessageContent?.message}
            ></CardError>
          ) : (
            <CardInfo
              content={[
                <InfoTextCard>
                  Revisa la siguiente información, en caso de que tus datos no
                  sean correctos o necesites actualizarlos por favor comunícate
                  con soporte para corregirlos en el <b>WhatsApp 5535784444</b>
                </InfoTextCard>
              ]}
            />
          )}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Modelorama"
                name="storeID"
                rules={[{ required: true }]}
              >
                <StyledSelect
                  onChange={onChangeStores}
                  placeholder={'Selecciona tu modelorama'}
                  allowClear
                >
                  {partnerData?.getProfile.stores.map((item: any) => {
                    return (
                      <Option key={item.storeID} value={item.storeID}>
                        {item.name}
                      </Option>
                    )
                  })}
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Calle"
                name="street"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="Calle" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                label="No. Exterior"
                name="extNumberStore"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="Núm. Exterior" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item label="No. Interior" name="intNumberStore">
                <Input disabled placeholder="Núm. Interior" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Código postal"
                name="postalCode"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="C.P." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Colonia"
                name="neighborhoodStore"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="Colonia" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <div>
                    Colonia Corrección
                    <Tooltip title="Seleccióna la colonia que corresponda al Modelorama seleccionado.">
                      <button style={{ marginLeft: '5px' }} type="button">
                        <InfoCircleFilled />
                      </button>
                    </Tooltip>
                  </div>
                }
                name="countyId"
                rules={[{ required: true }]}
              >
                <StyledSelect placeholder={'Selecciona tu colonia'} allowClear>
                  {countyData?.getCountyByPostalCode?.content.map(
                    (item: any) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      )
                    }
                  )}
                </StyledSelect>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Municipio/Alcaldia"
                name="city"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Estado"
                name="state"
                rules={[{ required: true }]}
              >
                <Input disabled placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </StyledCardSectionWhiteForm>

        <StyledCardSectionWhiteForm
          failed={
            StatusID === 3 &&
            invalidSection(MessageContent?.message, commerceKeywords)
          }
        >
          <h2>3. TIPO DE COMERCIO </h2>
          {StatusID === 3 &&
          invalidSection(MessageContent?.message, commerceKeywords) ? (
            <CardError
              status={'Se ha rechazado tu vinculación'}
              message={MessageContent?.message}
            ></CardError>
          ) : (
            <CardInfo
              content={[
                <>
                  <InfoTextCard>
                    Todos tus documentos deben ser legibles, en formato PDF de
                    preferencia.
                  </InfoTextCard>
                  <InfoTextCard>
                    Podrá ser un documento escaneado o fotografiado a color o
                    blanco y negro siempre y cuando se encuentre completo y
                    legible
                  </InfoTextCard>
                  <InfoTextCard>No debe ser mayor a 3 meses</InfoTextCard>
                  <InfoTextCard>
                    Deberá de coincidir la dirección y/o nombre del usuario en
                    comparación con la identificación oficial
                  </InfoTextCard>
                </>
              ]}
            />
          )}
          <Form.Item name={'personType'}>
            <Radio.Group onChange={onChangeTypePerson}>
              <Radio value={1}> Persona física </Radio>
              <Radio value={2}>
                {' '}
                Persona moral / física con actividad empresarial{' '}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <h2>SUBE TU DOCUMENTACIÓN</h2>
          <StyledUploadFilesButtons>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="ine"
                  label={
                    <div>
                      Identificación oficial frente
                      <Tooltip title="Mostrar ejemplos">
                        <button
                          style={{ marginLeft: '5px' }}
                          type="button"
                          data-testid="show-ine-modal"
                          onClick={() => onHelpChange(IneModalContent)}
                        >
                          <InfoCircleFilled />
                        </button>
                      </Tooltip>
                    </div>
                  }
                  extra="INE o Pasaporte en original, PDF, JPG o JPEG Frente 2MB"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => normFile(e, 1, 'ine')}
                  rules={[{ required: true, message: 'Seleccionar documento' }]}
                >
                  <Upload
                    maxCount={1}
                    name="logo"
                    listType="text"
                    accept=".jpeg, .jpg, .pdf"
                    beforeUpload={(file) => handleBeforeUplad(file, 'ine')}
                    showUploadList={false}
                    data-testid="ine-input"
                  >
                    <StyledUploadFile type="button">
                      <span data-testid="ine-file-name">
                        {files.fileNameINE}
                      </span>
                      <StyledUploadOutlined />
                    </StyledUploadFile>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="ine-back"
                  label={
                    <div>
                      Identificación oficial vuelta
                      <Tooltip title="Mostrar ejemplos">
                        <button
                          style={{ marginLeft: '5px' }}
                          type="button"
                          data-testid="show-ine-modal-back"
                          onClick={() => onHelpChange(IneModalContent)}
                        >
                          <InfoCircleFilled />
                        </button>
                      </Tooltip>
                    </div>
                  }
                  extra="INE o Pasaporte en original, PDF, JPG o JPEG Vuelta 2MB"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => normFile(e, 2, 'ine-back')}
                >
                  <Upload
                    maxCount={1}
                    name="logo"
                    listType="text"
                    accept=".jpeg, .jpg, .pdf"
                    beforeUpload={(file) => handleBeforeUplad(file, 'ine-back')}
                    showUploadList={false}
                    data-testid="ine-input-back"
                  >
                    <StyledUploadFile type="button">
                      <span data-testid="ine-file-name-back">
                        {files.fileNameINEBack}
                      </span>
                      <StyledUploadOutlined />
                    </StyledUploadFile>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="address"
                  label={
                    <div>
                      Comprobante de domicilio
                      <Tooltip title="Mostrar ejemplos">
                        <button
                          style={{ marginLeft: '5px' }}
                          type="button"
                          data-testid="show-address-modal"
                          onClick={() => onHelpChange(AdrressModalContent)}
                        >
                          <InfoCircleFilled />
                        </button>
                      </Tooltip>
                    </div>
                  }
                  extra="Recibo de luz, teléfono fijo, agua o gas No mayor a 3 meses, original PDF, JPG o JPEG 2MB"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => normFile(e, 3, 'address')}
                  rules={[{ required: true, message: 'Seleccionar documento' }]}
                >
                  <Upload
                    maxCount={1}
                    name="logo"
                    listType="text"
                    accept=".jpeg, .jpg, .pdf"
                    beforeUpload={(file) => handleBeforeUplad(file, 'address')}
                    showUploadList={false}
                    data-testid="address-input"
                  >
                    <StyledUploadFile type="button">
                      <span data-testid="address-file-name">
                        {files.fileNameAddress}
                      </span>
                      <StyledUploadOutlined />
                    </StyledUploadFile>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="bank"
                  label={
                    <div>
                      Edo. de cuenta bancario
                      <Tooltip title="Mostrar ejemplos">
                        <button
                          type="button"
                          style={{ marginLeft: '5px' }}
                          data-testid="show-bank-modal"
                          onClick={() => onHelpChange(BankStatementContent)}
                        >
                          <InfoCircleFilled />
                        </button>
                      </Tooltip>
                    </div>
                  }
                  extra="Carátula donde se visualice la clabe interbancaria, No mayor a 3 meses, original PDF, JPG o JPEG 2MB"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => normFile(e, 4, 'bank')}
                  rules={[{ required: true, message: 'Seleccionar documento' }]}
                >
                  <Upload
                    maxCount={1}
                    name="logo"
                    listType="text"
                    accept=".jpeg, .jpg, .pdf"
                    beforeUpload={(file) => handleBeforeUplad(file, 'bank')}
                    showUploadList={false}
                    data-testid="bank-input"
                  >
                    <StyledUploadFile type="button">
                      <span data-testid="bank-file-name">
                        {files.fileNameBank}
                      </span>
                      <StyledUploadOutlined />
                    </StyledUploadFile>
                  </Upload>
                </Form.Item>
              </Col>
              {typePerson === 2 && (
                <>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name="constitutive"
                      label="Acta constitutiva"
                      extra="Con poderes del representante legal, original PDF, JPG o JPEG"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => normFile(e, 5, 'constitutive')}
                    >
                      <Upload
                        maxCount={1}
                        name="logo"
                        listType="text"
                        accept=".jpeg, .jpg, .pdf"
                        beforeUpload={(file) =>
                          handleBeforeUplad(file, 'constitutive')
                        }
                        showUploadList={false}
                        data-testid="constitutive-input"
                      >
                        <StyledUploadFile type="button">
                          <span data-testid="constitutive-file-name">
                            {files.fileNameConstitutive}
                          </span>
                          <StyledUploadOutlined />
                        </StyledUploadFile>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name="hacienda"
                      label="Constancia de Situación Fiscal"
                      extra="Del mes que corre, original PDF, JPG o JPEG"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => normFile(e, 6, 'hacienda')}
                      rules={[
                        { required: true, message: 'Seleccionar documento' }
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        name="logo"
                        listType="text"
                        accept=".jpeg, .jpg, .pdf"
                        beforeUpload={(file) =>
                          handleBeforeUplad(file, 'hacienda')
                        }
                        showUploadList={false}
                        data-testid="hacienda-input"
                      >
                        <StyledUploadFile type="button">
                          <span data-testid="hacienda-file-name">
                            {files.fileNameHacienda}
                          </span>
                          <StyledUploadOutlined />
                        </StyledUploadFile>
                      </Upload>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </StyledUploadFilesButtons>
        </StyledCardSectionWhiteForm>

        <StyledCardSectionWhiteForm failed={false}>
          <h2>4. VENTAS ESTIMADAS MENSUALES </h2>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Tus ventas"
                name="sales"
                rules={[
                  {
                    required: true,
                    message: 'Debes elegir un rango de ventas'
                  }
                ]}
              >
                <StyledSelect placeholder={'Rango de ventas'} allowClear>
                  {yoloData.getYoloPagoCatalog.content.estimatedMonthlySales.map(
                    (item: any) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      )
                    }
                  )}
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
        </StyledCardSectionWhiteForm>

        <StyledCardSectionWhiteForm
          failed={
            StatusID === 3 &&
            invalidSection(MessageContent?.message, clabeKeywords)
          }
        >
          <h2>5. CLABE Interbancaria</h2>
          {StatusID === 3 &&
            invalidSection(MessageContent?.message, clabeKeywords) && (
              <CardError
                status={'Se ha rechazado tu vinculación'}
                message={MessageContent?.message}
              ></CardError>
            )}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Cuenta clabe"
                extra="Ingresa la clabe interbancaria de tu banco"
                name="clabe"
                rules={[
                  {
                    required: true,
                    pattern: RegExp('[0-9]{18}'),
                    message: 'Tu clabe interbancaria debe ser de 18 dígitos'
                  }
                ]}
              >
                <StyledInput
                  placeholder="18 caracteres"
                  data-testid="id-input-clabe"
                  maxLength={18}
                  onChange={handleClabeChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </StyledCardSectionWhiteForm>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} offset={2}>
            <StyledTermsAndConditions>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  He leído y acepto los términos y condiciones
                </Checkbox>
              </Form.Item>
              <p onClick={onClickTermsAndConditions}>
                Leer términos y condiciones
              </p>
            </StyledTermsAndConditions>
          </Col>
          <Col xs={20} sm={20} md={8} lg={8} xl={8} xxl={8} offset={2}>
            <StyledButtonPrimary
              data-testid="button-entrar"
              type="primary"
              htmlType="submit"
              disabled={disabled}
            >
              HACER INTEGRACIÓN
            </StyledButtonPrimary>
          </Col>
        </Row>
      </Form>
      <ModalResponse
        isModalVisible={help.isModalVisible}
        content={help.content}
        closable={true}
        onCancel={onHelpClose}
        width={1000}
        data-testid="modal-help-id"
      />
    </StyledFormYoloPagoContainer>
  )
}

export default FormYoloPago
