import styled from 'styled-components'
import { Input, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

interface InputFileProps {
  typeName: string
}

type StyledContainer = {
  failed: boolean
}

export const TitleYolopago = styled.h2`
  font-size: 28px;
  padding-top: 20px;
  color: #0b4793;
  text-transform: uppercase;

  @media all and (max-width: 767px) {
    font-size: 20px;
    padding-top: 10px;
  }
`
export const InfoTextCard = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 0;

  @media all and (max-width: 767px) {
    font-size: 11px;
  }
`

export const StyledInput = styled(Input)`
  border: 1px solid #000000 !important;
`

export const StyledSelect = styled(Select)`
  font-family: Montserrat;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;

  .ant-select-selection-item {
    font-weight: 600 !important;
  }

  .ant-select-selector {
    text-align: left;
    justify-content: baseline;
    align-items: center;
    background-color: #fff;
    border-color: #000000 !important;
    color: #000000;
    height: 40px !important;
    border-radius: 5px !important;
    margin: 0;
  }
  .ant-select-arrow {
    color: #939393;
  }
`

export const StyledUploadFile = styled.button`
  width: 100%;
  height: 40px;
  border: solid 1px #000000;
  border-radius: 4px;
  border: solid 1px #000;
  background-color: #fff;
  padding-right: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const StyledUploadOutlined = styled(UploadOutlined)`
  font-size: 1.3rem;
  color: #000;
`

export const IneImg = styled.img`
  width: 361px;
  height: 227px;
  margin-left: 20px;

  @media all and (max-width: 767px) {
    width: 280px;
    height: 180px;
    margin-left: 10px;
    margin-top: 5px;
  }
`

export const AddressImg = styled.img`
  width: 400px;
  height: 500px;
  margin-left: 20px;

  @media all and (max-width: 767px) {
    width: 280px;
    height: 380px;
    margin-left: 10px;
    margin-top: 5px;
  }
`

export const RowTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;

  @media all and (max-width: 767px) {
    padding-bottom: 5px;
    padding-top: 5px;
  }
`

export const SubTitleContainer = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 0;
  margin-left: 8px;

  @media all and (max-width: 767px) {
    font-size: 15px;
  }
`

export const InfoTextModal = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 10px 0px 10px 20px;

  @media all and (max-width: 767px) {
    font-size: 13px;
    margin-left: 10px;
  }
`

export const NumberTextConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  border-radius: 20px;
  background-color: #0b4793;
  color: #fff;
`

export const SquareContainer = styled.div`
  width: 75%;
  height: 86px;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 12px #0000000a;
  p {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 25px;
    margin-left: 35px;
  }
`
export const Alerts = styled.p`
  color: red;
`

export const FormContainer = styled.div`
  width: 95%;
  height: auto;
  background-color: #fff;
  margin-top: 30px;
  padding-left: 55px;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 34%;
`

export const TitleCards = styled.p`
  font-size: 26px;
  color: #385cad;
  text-transform: uppercase;
  margin-top: 18px;
  margin-left: 0px;
`

export const InputFile = styled.div<InputFileProps>`
  p {
    margin-top: 5px;
    font-size: 12px;
  }
  .custom-file-input {
    color: transparent;
    width: 200px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: '${(props) => props.typeName}';
    color: black;
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    width: 100%;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .icon {
    position: absolute;
    margin-left: -30px;
    margin-top: 10px;
  }
`

export const StyledFormYoloPagoContainer = styled.div`
  padding: 2%;
  margin: 0 0 20px 0;
`
export const StyledCardSectionWhiteForm = styled.div<StyledContainer>`
  background-color: white;
  padding: 10px 30px;
  margin: 20px 0;
  border: 1px solid #f00c27;
  border: ${({ failed }) => (failed ? '1px solid #f00c27' : 'none')};

  h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #385cad;
  }
`
export const StyledUploadFilesButtons = styled.div``
export const StyledTermsAndConditions = styled.div`
  p {
    color: #385cad;
    font-size: 12px;
    margin: -25px 0 20px 25px;
    cursor: pointer;
    text-decoration: underline;
  }
`
