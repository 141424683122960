// Packages
import React from 'react'
import SearchEngineOptimization from '../../../components/SEO'

// Components
import YolopagoContainer from '../../../containers/YolopagoContainer'
import LayoutMain from '../../../layout'

const Interaction: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="YoloPago" />
      <YolopagoContainer />
    </LayoutMain>
  )
}

export default Interaction
