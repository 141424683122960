import React from 'react'
import CardInfo from '../CardInfo'
import {
  TitleYolopago,
  InfoTextCard,
  IneImg,
  NumberTextConatiner,
  RowTitleContainer,
  SubTitleContainer,
  InfoTextModal,
  AddressImg
} from './styledComponents'
import { Row, Col } from 'antd'
import config from '../../data/env'

export const invalidSection = (sentence: string, keywords: string[]) => {
  return keywords.some((key) => sentence.toLowerCase().includes(key))
}

export const IneModalContent = (
  <div data-testid="ine-modal-content">
    <TitleYolopago>Indentificación oficial</TitleYolopago>
    <CardInfo
      content={[
        <>
          <InfoTextCard>
            La credencial para votar deberá contener{' '}
            <b>parte frontal, parte trasera y encontrarse vigente</b>
          </InfoTextCard>
          <InfoTextCard>
            El pasaporte deberá contener{' '}
            <b>parte frontal y encontrarse vigente</b>
          </InfoTextCard>
          <InfoTextCard>
            Podrá ser un documento{' '}
            <b>escaneado o fotografiado a color o blanco y negro, </b>
            siempre y cuando se encuentre <b>completo, legible</b> y no se
            muestre algún otro objeto distinto al documento, de acuerdo con lo
            siguiente
          </InfoTextCard>
        </>
      ]}
    />
    <RowTitleContainer>
      <NumberTextConatiner>1</NumberTextConatiner>
      <SubTitleContainer>
        Credencial para votar, frente y vuelta
      </SubTitleContainer>
    </RowTitleContainer>
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/ine-frente-color.png`}
    />
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/ine-atras-color.png`}
    />
    <InfoTextModal>Credencial para votar frente y vuelta a color</InfoTextModal>
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/ine-frente-bn.png`}
    />
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/ine-atras-bn.png`}
    />
    <InfoTextModal>
      Credencial para votar frente y vuelta en blanco y negro
    </InfoTextModal>
    <RowTitleContainer>
      <NumberTextConatiner>2</NumberTextConatiner>
      <SubTitleContainer>Pasaporte</SubTitleContainer>
    </RowTitleContainer>
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/pasaporte-frente-color.png`}
    />
    <InfoTextModal>Pasaporte vigente a color</InfoTextModal>
    <IneImg
      src={`${config.app.url}/media/images/exampleDocuments/pasaporte-bn.png`}
    />
    <InfoTextModal>Pasaporte vigente en blanco y negro</InfoTextModal>
  </div>
)

export const AdrressModalContent = (
  <>
    <TitleYolopago>COMPROBANTES DE DOMICILIO</TitleYolopago>
    <CardInfo
      content={[
        <>
          <InfoTextCard>
            El comprobante de domicilio deberá de encontrarse{' '}
            <b>vigente con una antigüedad no mayor a 3 meses</b>
          </InfoTextCard>
          <InfoTextCard>No es necesario que se exhiba pagado</InfoTextCard>
          <InfoTextCard>
            Podrá ser un documento{' '}
            <b>escaneado o fotografiado a color o blanco y negro, </b>siempre y
            cuando se encuentre <b>completo, legible </b>y no se muestre algún
            otro objeto distinto al documento
          </InfoTextCard>
          <InfoTextCard>
            Deberá de <b>coincidir la dirección y/o el nombre del usuario </b>en
            comparación <b>con la identificación oficial </b>proporcionada, de
            acuerdo con lo siguiente:
          </InfoTextCard>
        </>
      ]}
    />
    <RowTitleContainer>
      <NumberTextConatiner>1</NumberTextConatiner>
      <SubTitleContainer>Recibo de luz</SubTitleContainer>
    </RowTitleContainer>
    <Row>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/cfe-color.webp`}
        />
        <InfoTextModal>Recibo de luz a color</InfoTextModal>
      </Col>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/cfe-bn.png`}
        />
        <InfoTextModal>Recibo de luz blanco y negro</InfoTextModal>
      </Col>
    </Row>
    <RowTitleContainer>
      <NumberTextConatiner>2</NumberTextConatiner>
      <SubTitleContainer>
        Recibo de telefonía fija (IZZI-TELMEX-TOTALPLAY)
      </SubTitleContainer>
    </RowTitleContainer>
    <Row>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/telefonia-color.webp`}
        />
        <InfoTextModal>Recibo de telefonía a color</InfoTextModal>
      </Col>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/telefonia-bn.png`}
        />
        <InfoTextModal>Recibo de telefonía en blanco y negro</InfoTextModal>
      </Col>
    </Row>
    <RowTitleContainer>
      <NumberTextConatiner>3</NumberTextConatiner>
      <SubTitleContainer>Recibo de agua</SubTitleContainer>
    </RowTitleContainer>
    <Row>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/agua-color.webp`}
        />
        <InfoTextModal>Recibo de agua a color</InfoTextModal>
      </Col>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/agua-bn.png`}
        />
        <InfoTextModal>Recibo de agua en blanco y negro</InfoTextModal>
      </Col>
    </Row>
    <RowTitleContainer>
      <NumberTextConatiner>4</NumberTextConatiner>
      <SubTitleContainer>Recibo de gas</SubTitleContainer>
    </RowTitleContainer>
    <Row>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/gas-color.webp`}
        />
        <InfoTextModal>Recibo de gas a color</InfoTextModal>
      </Col>
      <Col>
        <AddressImg
          src={`${config.app.url}/media/images/exampleDocuments/gas-bn.webp`}
        />
        <InfoTextModal>Recibo de gas en blanco y negro</InfoTextModal>
      </Col>
    </Row>
  </>
)

export const BankStatementContent = (
  <>
    <TitleYolopago>ESTADO DE CUENTA BANCARIO</TitleYolopago>
    <CardInfo
      content={[
        <>
          <InfoTextCard>
            El documento deberá ser{' '}
            <b>emitido y contener el logo de la institución financiera </b>
          </InfoTextCard>
          <InfoTextCard>
            Nombre del propietario de la cuenta (obligatoriamente a nombre del
            administrador de comercio/representante registrado en Yolopago) y
            dirección
          </InfoTextCard>
          <InfoTextCard>Número de cuenta</InfoTextCard>
          <InfoTextCard>Número de cuenta CLABE</InfoTextCard>
          <InfoTextCard>
            Encontrarse vigente con una antigüedad <b>no mayor a tres meses</b>
          </InfoTextCard>
          <InfoTextCard>
            * Los datos marcados con “X” no son requeridos para la contratación
            del servicio, sin embargo debe visualizarse todo el documento
          </InfoTextCard>
        </>
      ]}
    />
    <AddressImg
      src={`${config.app.url}/media/images/exampleDocuments/edocuenta-1.webp`}
    />
    <AddressImg
      src={`${config.app.url}/media/images/exampleDocuments/edocuenta-2.webp`}
    />
  </>
)
