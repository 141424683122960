export const generalKeywords = [
  'nombre',
  'apellido',
  'paterno',
  'materno',
  'rfc',
  'curp',
  'correo',
  'email',
  'télefono',
  'celular'
]

export const modeloramaKeywords = [
  'modelorama',
  'colonia',
  'exterior',
  'inetrior'
]

export const commerceKeywords = [
  'ine',
  'identificación',
  'comprobante',
  'domicilio',
  'recibo',
  'luz',
  'telefonía',
  'agua',
  'gas',
  'estado',
  'cuenta',
  'vigente',
  '3 meses',
  'escaneado',
  'legible',
  'acta',
  'constitutiva',
  'constancia',
  'fiscal'
]

export const clabeKeywords = ['clabe', 'interbancaria']
