import { gql } from '@apollo/client'
export const CREATE_AFFILIATION_YOLOPAGO = gql`
  mutation createModeloramaYP(
    $commerce_type: String!
    $commerce_name: String!
    $rfc: String!
    $email: String!
    $name: String!
    $ap_materno: String!
    $ap_paterno: String!
    $phone: String!
    $street: String!
    $county_id: Int!
    $activities_id: Int!
    $estimatedMonthlySales_id: Int!
    $curp: String!
    $clabe: String!
    $acepto_terminos_y_condiciones: Boolean!
    $fecha_hora_terminos_y_condiciones: String!
    $documentList: [Documents]
    $terminalList: [String!]!
  ) {
    createModeloramaYP(
      dataNewModeloramaYP: {
        commerce_type: $commerce_type
        commerce_name: $commerce_name
        rfc: $rfc
        email: $email
        name: $name
        ap_materno: $ap_materno
        ap_paterno: $ap_paterno
        phone: $phone
        street: $street
        county_id: $county_id
        activities_id: $activities_id
        estimatedMonthlySales_id: $estimatedMonthlySales_id
        curp: $curp
        clabe: $clabe
        acepto_terminos_y_condiciones: $acepto_terminos_y_condiciones
        fecha_hora_terminos_y_condiciones: $fecha_hora_terminos_y_condiciones
        documentList: $documentList
        terminalList: $terminalList
      }
    ) {
      message
      status
      date
    }
  }
`
