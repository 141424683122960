import styled from 'styled-components'

export const InfoCardContainer = styled.div`
  border-radius: 7px;
  border-color: red;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 15px;
  background-color: #ffe387;
`
