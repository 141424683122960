import { gql } from '@apollo/client'
export const GET_YOLOPAGO_DATA = gql`
  {
    getYoloPagoCatalog {
      status
      message
      content {
        estimatedMonthlySales {
          id
          name
        }
        banks {
          id
          name
          key
        }
        documentTypes {
          id
          name
        }
        activities {
          id
          name
        }
      }
    }
  }
`
export const GET_COUNTY_BY_POSTAL_CODE = gql`
  query getCountyByPostalCode($zipCode: String!) {
    getCountyByPostalCode(zipCode: $zipCode) {
      status
      message
      content {
        id
        name
        zipcode
        province
        state
      }
    }
  }
`
export const GET_TERMS_YOLOPAGO = gql`
  {
    getTermsAndConditions {
      status
      message
      content {
        term_and_conditions
      }
    }
  }
`
