import { Button } from 'antd'
import styled from 'styled-components'

export const StyledButtonPrimary = styled(Button)`
  &&& {
    background-color: #0b4793;
    border: 2px solid #0b4793;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 100px;
    font-weight: 600;
    width: 100%;

    :disabled {
      color: white;
      border: solid 1px #d9d9d9;
      background: #d2d2d2;
      text-shadow: none;
      box-shadow: none;
    }
  }
`

export const StyledButtonDefault = styled(StyledButtonPrimary)`
  &&& {
    background-color: transparent;
    border: none;
    height: 40px;
    border-radius: 100px;
    color: #0b4793;

    :disabled {
      color: white;
      border: solid 1px #d9d9d9;
      background: #d2d2d2;
      text-shadow: none;
      box-shadow: none;
    }
  }
`
export const StyledButtonPrimaryDark = styled(StyledButtonPrimary)`
  &&& {
    background-color: #0a2240;
    border: 2px solid #0a2240;
    height: 50px;
    border-radius: 4px;
    font-size: 16px;
    color: white;

    :disabled {
      color: white;
      border: solid 1px #d9d9d9;
      background: #d2d2d2;
      text-shadow: none;
      box-shadow: none;
    }
  }
`
