import styled from 'styled-components'

export const TitleYolopago = styled.h2`
  font-size: 30px;
  padding-top: 20px;
  color: #0b4793;

  @media all and (max-width: 767px) {
    font-size: 20px;
    padding-top: 10px;
  }
`

export const StyledSubtitle = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
`
