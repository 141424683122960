import React from 'react'
import { Row, Col } from 'antd'
import { InfoCardContainer } from './styledComponents'
import { InfoCircleOutlined } from '@ant-design/icons'

type propsInfoCard = {
  content: any
}

const CardInfo: React.FC<propsInfoCard> = ({ content }) => {
  return (
    <InfoCardContainer>
      <Row gutter={16}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} xxl={1}>
          <InfoCircleOutlined
            data-testid="info"
            style={{ fontSize: '25px', color: '#4d4d4d', paddingTop: '10px' }}
          />
        </Col>
        <Col xs={22} sm={22} md={23} lg={23} xl={23} xxl={23}>
          {content}
        </Col>
      </Row>
    </InfoCardContainer>
  )
}

export default CardInfo
